import axios from '../libs/gadgetsAjaxRequest'

const getmokacategories = (data) => {
    return axios.request({
        url: 'api/moka/getmokacategories',
        method: 'post',
        data
    })
}
const getmokacard = (data) => {
    return axios.request({
        url: 'api/moka/getmokacard',
        method: 'post',
        data
    })
}

const getmokatemplates = (data) => {
    return axios.request({
        url: 'api/moka/getmokatemplates',
        method: 'post',
        data
    })
}

//获取上传图片token
const GetQrCode = (data) => {
    return axios.request({
        url: 'api/loginapi/GetQrCode',
        method: 'post',
        data
    })
}

//获取上传图片token
const uploadimg = (data) => {
    return axios.request({
        url: 'api/moka/uploadimg',
        method: 'post',
        data
    })
}


export {
    getmokacategories, //获取空间分组和风格分组
    getmokacard, //获取色卡
    getmokatemplates, //获取空间模板
    GetQrCode,
    uploadimg
}
