<template> <!-- 魔卡 -->
    <div id="magicCard" :class="isVertical == 'vertical' ? 'vertical' : ''">
        <div class="box">
            <div class="content" id="img">
                <img v-if="bottomImg" :src="bottomImg"/>
                <img v-if="showTopImg" :src="topImg"/>
                
                <div class="qrcodeBox" v-if="empInfoData" :style="isOpacity ? 'opacity:1' : 'opacity:0'">
                    <img :src="empInfoData.coverImg" class="header"/>
                    <p class="shopName">{{empInfoData.shopName}}</p>
                    <img class="qrcode" v-if="qrCode" :src="qrCode">
                    <div>
                        <p><i class="iconfont icon-gerenzhongxin"></i>{{empInfoData.realName}}</p>
                        <p><i class="iconfont icon-ziyuan"></i>{{empInfoData.phone}}</p>
                    </div>
                </div>
            </div>
            
            <van-swipe class="my-swipe" @change="changeSwiper" :show-indicators="false" :loop="false" :initial-swipe="currentImg">
                <van-swipe-item v-for="(item, index) in templateList" :key="index" >
                    <div>
                        <img :src="item.ImgUrl"/>
                    </div>
                </van-swipe-item>
            </van-swipe>
            <p v-if="isVertical != 'vertical'" class="currentTemplateName">当前方案：{{currentTemplateName}}</p>
        </div>

        <div v-if="isVertical == 'vertical'" class="verticalBottomBtnGroup">
            <van-row>
                <van-col span="24">
                    <div class="bottomLeft">
                        <button @click="share">
                            <i class="iconfont icon-xin1"></i>
                            收藏
                        </button>
                        <button @click="styleModal = true">
                            <p>空间样式</p>
                            <i class="iconfont icon-yangshi2"></i>
                        </button>
                        <div>
                            <img :src="`${currentTemplateImg}?imageView2/1/w/102/h/72`"/>
                            <p>{{currentTemplateName}}</p>
                        </div>
                    </div>
                </van-col>
            </van-row>
            <van-row>
                <van-col span="24">
                    <div class="verticalBottomBox">
                        <div class="selectCard">
                            <button :class="{'active': cardType == 1}" @click="changeCard(1)">大众色卡</button>
                            <button :class="{'active': cardType == 2}" @click="changeCard(2)">店铺特色</button>
                        </div>
                        <div class="bottomRight">
                            <ul v-if="cardType == 1">
                                <li v-for="(item, index) in cardList" v-if="item.Common == 1 && cardType == 1" @click="selectCard(item.ImgUrl, index, item.Id)">
                                    <div :class="cardIndex == index ? 'cardItem active' : 'cardItem'">
                                        <img :src="`${item.ImgUrl}?imageView2/1/w/60/h/42`"/>
                                        <p>{{item.Name}}</p>
                                    </div>
                                </li>
                            </ul>
                            <ul v-if="cardType == 2">
                                <li v-for="(item, index) in cardList" v-if="item.Common == 0 && cardType == 2" @click="selectCard(item.ImgUrl, index, item.Id)">
                                    <div :class="cardIndex == index ? 'cardItem active' : 'cardItem'">
                                        <img :src="item.ImgUrl"/>
                                        <p>{{item.Name}}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </van-col>
            </van-row>

        </div>
        <div v-else class="bottomBtnGroup">
            <van-row>
                <van-col span="7">
                    <div class="bottomLeft">
                        <button @click="share">
                            <i class="iconfont icon-xin1"></i>
                            收藏
                        </button>
                        <button @click="styleModal = true">
                            <p>空间样式</p>
                            <i class="iconfont icon-yangshi2"></i>
                        </button>
                        <div>
                            <button :class="{'active': cardType == 1}" @click="changeCard(1)">大众色卡</button>
                            <button :class="{'active': cardType == 2}" @click="changeCard(2)">店铺特色</button>
                        </div>
                    </div>
                </van-col>
                <van-col span="17">
                    <div class="bottomRight">
                        <ul>
                            <li v-for="(item, index) in cardList" v-if="item.Common == 1 && cardType == 1" @touchstart="touchstart(item.ImgUrl, index)" @touchend="touchend(item.ImgUrl, index, item.Id)" @touchmove="touchmove">
                                <van-progress :percentage="percentage" :show-pivot="false" v-if="touchIndex == index"/>
                                <div :class="cardIndex == index ? 'cardItem active' : 'cardItem'">
                                    <img :src="`${item.ImgUrl}?imageView2/1/w/60/h/42`"/>
                                    <p>{{item.Name}}</p>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li v-for="(item, index) in cardList" v-if="item.Common == 0 && cardType == 2" @touchstart="touchstart(item.ImgUrl, index)" @touchend="touchend(item.ImgUrl, index, item.Id)" @touchmove="touchmove">
                                <van-progress :percentage="percentage" :show-pivot="false" v-if="touchIndex == index"/>
                                <div :class="cardIndex == index ? 'cardItem active' : 'cardItem'">
                                    <img :src="item.ImgUrl"/>
                                    <p>{{item.Name}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </van-col>
            </van-row>
        </div>

        <van-popup v-model="styleModal" position="left" class="styleModal">
            <div class="header">空间选择</div>
            <div class="content">
                <div>
                    <div>
                        <h4 class="title">空间类型</h4>
                        <ul>
                            <li ref="type" v-for="(item, index) in spaceTypeList" :key="index" @click="selectType($event, 1, item.Id)">{{item.Name}}</li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="title">风格类型</h4>
                        <ul>
                            <li ref="type" v-for="(item, index) in styleTypeList" :key="index" @click="selectType($event, 2, item.Id)">{{item.Name}}</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <van-list ref="templateList" v-model="loading" @load="onLoad" :finished="finished" finished-text="没有更多了">
                        <ul>
                            <li v-for="(item, index) in templateList" :key="index" @click="changeStyle(item.ImgUrl, index, item.Id, item.Name, item.ExampleUrl)" :class="{'active': styleIndex == index}">
                                <img :src="`${item.ExampleUrl}?imageView2/1/w/102/h/72`"/>
                                {{item.Name}}
                            </li>
                        </ul>
                    </van-list>
                </div>
            </div>
            <div class="footer">
                <div>
                    <button @click="reset"><i class="iconfont icon-zhongzhi"></i>全部重置</button>
                </div>
                <div>
                   <!--  <button>确认</button>
                    <button @click="styleModal = false">取消</button> -->
                </div>
            </div>
        </van-popup>

        <div ref="qrcode" id="qrcode" style="display:none"></div>
        <van-popup v-model="imgModal" class="imgModal">
            <img :src="imgCode"/>
            <span>手机扫码下载</span>
        </van-popup>

        <van-popup v-model="cardDetailsModal" class="cardDetailsModal">
            <img :src="cardDetails"/>
        </van-popup>


        <div class="ladingBox" v-if="isLoading">
            <rotate-square5 v-if="isLoading" class="loading"></rotate-square5>
        </div>
    </div>
</template>

<script>
import Html2canvas from 'html2canvas'
import {RotateSquare5} from 'vue-loading-spinner'
import {getmokacategories, getmokacard, getmokatemplates, GetQrCode, uploadimg} from '../api/magicCard'
import {getEmpInfo} from '../api/index'
export default {
    data () {
        return {
            token: '',
            styleModal: false, //空间样式弹窗
            templateData: { //获取空间模板入参
                token: '',
                spaceIds: [], //空间id
                styleIds: [], //样式id
                pagesize: 20,
                index: 1, //页码
            },
            cardData: { //色卡入参
                token: '',
                idnex: 1,
                pagesize: ''
            },
            spaceTypeList: '', //空间类型
            styleTypeList: '', //风格类型
            spaceIds: [], //空间id
            styleIds: [], //样式id
            styleIndex: -1, // 样式模板索引
            cardList: [],
            cardIndex: -1, //色卡索引
            topImg: '', //上面图片
            bottomImg: '', //下面图片
            imgModal: false,
            posterImg: '', //canvas生成
            qrCode: '', //个人二维码
            templateList: '', //样式模板
            imgCode: '', //二维码
            cardType: 1, //色卡类型  1大众色卡 2店铺特色
            isLoading:false, //控制加载动画
            loop: 0, //存储定时器
            cardDetailsModal: false, //色卡详情弹窗
            cardDetails: '', //色卡详情图片
            progress: 0, //存储进度条定时器
            clearPercentage: 0, //存储进度定时器
            percentage: 0, //进度
            touchIndex: -1, //长按索引
            empInfoData: '',
            templateid: '', //模板id
            cardid: '', //色板id
            currentImg: 0, //轮播图索引
            showTopImg: false, //生成时再显示
            loading: false,
            finished: false,
            isVertical: '', //是否为竖屏
            isOpacity: false, //左上角信息窗口显示
            currentTemplateName: '', //当前模板名称
            currentTemplateImg: '', //当前模板图片
        }
    },
    mounted () {
        const {token, vertical} = this.$route.query;
        vertical && (this.isVertical = vertical);
        if (vertical) {
            this.isVertical = vertical;
            this.templateData.pagesize = 40;
        } else {
            this.templateData.pagesize = 20;
        }
        this.token = token;
        this.categories(0);
        this.categories(1);
        // this.getTemplate();
        this.getCode();
        this.getCard();
        this.getTemplate();
        this.empInfo();
        window.setPosition = this.setPosition;
    },
    components: {RotateSquare5},
    methods: {

        /* 获取空间分组和风格分组 */
        async categories (type) {
            const {token} = this;
            var arr = {token, type}
            let res = await getmokacategories(arr);
            const {status, data} = res;
            if (status == 0) {
                if (type == 0) {
                    this.spaceTypeList = data;
                } else {
                    this.styleTypeList = data;
                }
            }
        },
        
         //设置position
        setPosition (position) {
            this.position = parseInt(position);
            localStorage.setItem('position',position);
        },

        /* 获取空间模板 */
        async getTemplate () {
            const {templateData, token} = this;
            this.templateData.token = token;
            this.spaceIds ? this.templateData.spaceIds = this.spaceIds.join(',') : this.templateData.spaceIds = this.spaceIds;
            this.styleIds ? this.templateData.styleIds = this.styleIds.join(',') : this.templateData.styleIds = this.styleIds;
            let res = await getmokatemplates(templateData);
            this.templateList = res.data.data;
            this.topImg = res.data.data[0].ImgUrl;
            this.templateid = res.data.data[0].Id;
            this.currentTemplateName = res.data.data[0].Name;
            this.currentTemplateImg = res.data.data[0].ExampleUrl;
        },

        /* 加载更多模板 */
        async onLoad () {
            this.templateData.index++;
            this.spaceIds ? this.templateData.spaceIds = this.spaceIds.join(',') : this.templateData.spaceIds = this.spaceIds;
            this.styleIds ? this.templateData.styleIds = this.styleIds.join(',') : this.templateData.styleIds = this.styleIds;
            let res = await getmokatemplates(this.templateData);
            console.log(res);
            const {status, data} = res;
            if (data.data.length > 0) {
                this.templateList.push(...data.data);
                this.loading = false;
            } else {
                this.finished = true;
            }
        },

        /* 获取色卡 */
        async getCard () {
            const {cardData, token} = this;
            this.cardData.token = token;
            let res = await getmokacard(cardData);
            const {status, data} = res;
            console.log(res);
            if (status == 0) {
                this.cardList = data.data;
            }
        },

        /* 获取员工信息 */
        async empInfo () {
            let res = await getEmpInfo(this.token);
            this.empInfoData = res.data;
            console.log(this.empInfoData);
        },

        /* 选择类型 */
        selectType ({target}, type, id) {
            let isSelect = target.className.indexOf('active') == -1;
            if (isSelect) {
                target.className = 'active';
                type == 1 ? 
                this.spaceIds.push(id) : 
                this.styleIds.push(id);
            } else {
                const {spaceIds, styleIds} = this;
                target.className = '';
                if (type == 1) {
                    for (let key in spaceIds) {
                        if (spaceIds[key] == id) {
                            this.spaceIds.splice(key, 1);
                            break;
                        }
                    }
                } else {
                    for (let key in styleIds) {
                        if (styleIds[key] == id) {
                            this.styleIds.splice(key, 1);
                            break;
                        }
                    }
                }
            }
            this.templateData.index = 1;
            this.loading = false;
            this.finished = false;
            this.$refs.templateList.$el.parentNode.scrollTop = 0;
            this.styleIndex = -1;
            this.getTemplate();
        },

        //选择样式模板
        changeStyle (img, index, id, templateName, templateImg) {
            this.topImg = img;
            this.styleIndex = index;
            this.templateid = id;
            this.styleModal = false;
            this.currentImg = index;
            this.currentTemplateName = templateName;
            this.currentTemplateImg = templateImg;
        },

        /* 轮播滚动 */
        changeSwiper (index) {
            const {templateList} = this;
            this.topImg = this.templateList[index].ImgUrl;
            this.templateid = this.templateList[index].Id;
            this.currentTemplateName = this.templateList[index].Name;
            this.currentTemplateImg = this.templateList[index].ExampleUrl;
            if (index+1 == templateList.length) {
                this.onLoad();
                this.currentImg = templateList.length+1;
            }
        },

        //选择色卡
        selectCard(img, index, id) {
            this.bottomImg = img;
            this.cardIndex = index;
            this.cardid = id;
        },

        //分享
        share () {
            this.showTopImg = true;
            this.isOpacity = true; 
            const {topImg, bottomImg} = this;
            if (!topImg || !bottomImg) {
                this.$toast('请完善色卡选择');
                return;
            }
            this.isLoading = true;
            setTimeout(() => {
                let poster = document.getElementById("img");
                Html2canvas(poster,{
                    allowTaint:false,
                    useCORS: true,
                    timeout: 1000
                })
                .then(canvas => {
                    this.posterImg = canvas.toDataURL("image/png");
                    this.upload(this.posterImg);
                });
            });
        },

        //上传base64图片
        async upload (base64) {
            const {token, templateid, cardid} = this;
            let res = await uploadimg({token, imgdata: base64, templateid, cardid});
            if (res.status == 0) {
                /* let qrcode = new Qrcode('qrcode', {
                        width: 200,
                        height: 200, // 高度
                        text: decodeURIComponent(res.data), // 二维码内容
                        render: 'table',   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                 });
                let myCanvas = document.getElementById("qrcode").getElementsByTagName("canvas");
                this.imgCode = myCanvas[0].toDataURL("image/png");
                 this.imgModal = true; */
                this.isLoading = false;
                this.showTopImg = false;
                this.$toast('已加入收藏夹');
                this.isOpacity = false; 
            }
        },

        //获取二维码
        async getCode () {
            const {token} = this;
            let res = await GetQrCode({imid: token});
            this.qrCode = res.data.qrcode;
        },

        /* 选择色卡 */
        changeCard (num) {
            this.cardType = num;
            this.cardIndex = -1;
        },

        /* 长按 */
        touchstart (img, index) {
            clearTimeout(this.loop);
            clearTimeout(this.progress);
            clearTimeout(this.clearPercentage);

            this.clearPercentage = setInterval(() => {
                this.percentage++;
            }, 29)

            this.loop = setTimeout(() => {
                // console.log('长按');
                this.cardDetails = img;
                this.cardDetailsModal = true;
                this.loop = 0;
                this.touchIndex = -1;
                this.percentage = 0;
                clearTimeout(this.clearPercentage);
            }, 3000);

            this.progress = setTimeout(() => {
                // console.log("开始长按");
                this.touchIndex = index;
            },500);

        },

        /* 抬起 */
        touchend (img, index, id) {
            clearTimeout(this.loop);
            clearTimeout(this.progress);
            clearTimeout(this.clearPercentage);
            this.touchIndex = -1;
            this.percentage = 0;
            if (this.loop !== 0) {
                // console.log('长按时间不够');
                this.selectCard(img, index, id);
            }
        },

        /* 移动 */
        touchmove () {
            clearTimeout(this.loop);
            clearTimeout(this.progress);
            clearTimeout(this.clearPercentage);
            this.percentage = 0;
        },

        /* 重置 */
        reset () {
            this.$refs.type.map(data => {
                data.className = '';
            });
            this.spaceIds = [];
            this.styleIds = [];
            this.templateData.index = 1;
            this.loading = false;
            this.finished = false;
            this.$refs.templateList.$el.parentNode.scrollTop = 0;
            this.getTemplate();
        }
    }
}
</script>


<style lang="less" src="@/style/magicCard.less">

</style>